import { defineComponent, ref, onMounted } from "vue";
export default defineComponent({
  setup: function setup() {
    var bannerList = ref([{
      type: 1,
      url: require('../../assets/product_img2.png')
    }, {
      type: 2,
      url: require('../../assets/product_img1.png')
    }, {
      type: 1,
      url: require('../../assets/product_img2.png')
    }, {
      type: 2,
      url: require('../../assets/product_img1.png')
    }]);
    var bannerList2 = ref([{
      // index: 0,
      type: 1,
      url: require('../../assets/product_img1.png'),
      text1: 'BC60A启明星',
      text2: '专为青少年健康数字阅读打造',
      text3: 'BUILT FOR TEEN E-HEALTH READING'
    }, {
      // index: 0,
      type: 2,
      url: require('../../assets/product_img2.png'),
      text1: 'BC520绘本通',
      text2: '专为儿童健康阅读打造的彩色电子纸终端',
      text3: 'A COLOR E-PAPER TERMINAL DESIGNED FOR CHILDRENS HEALITHY READING'
    }, {
      // index: 3,
      type: 1,
      url: require('../../assets/product_img1.png'),
      text1: 'BC60A启明星',
      text2: '专为青少年健康数字阅读打造',
      text3: 'BUILT FOR TEEN E-HEALTH READING'
    }, {
      // index: 2,
      type: 2,
      url: require('../../assets/product_img2.png'),
      text1: 'BC520绘本通',
      text2: '专为儿童健康阅读打造的彩色电子纸终端',
      text3: 'A COLOR E-PAPER TERMINAL DESIGNED FOR CHILDRENS HEALITHY READING'
    }]);
    var bannerIndex = ref(0);

    var changeBanner = function changeBanner(index) {
      // console.log("-------1",index);
      bannerIndex.value = index;
    };

    var handleHuiben = function handleHuiben() {
      window.open('https://bcpicbook.yuedu365.cn/#/home');
    };

    var handleQimingxing = function handleQimingxing() {
      window.open('https://qmx.yuedu365.cn/#/homenew');
    }; // 顶部banner切换


    var topbanner = ref(null);

    var next = function next() {
      topbanner.value.next();
    };

    var pre = function pre() {
      topbanner.value.prev();
    };

    var handlebackTop = function handlebackTop() {
      content.scrollIntoView();
    };

    onMounted(function () {
      handlebackTop();
      bannerList2.value = [{
        // index: 0,
        type: 1,
        url: require('../../assets/product_img2.png'),
        text1: 'BC520绘本通',
        text2: '专为儿童健康阅读打造的彩色电子纸终端',
        text3: 'A COLOR E-PAPER TERMINAL DESIGNED FOR CHILDRENS HEALITHY READING'
      }, {
        // index: 1,
        type: 2,
        url: require('../../assets/product_img1.png'),
        text1: 'BC60A启明星',
        text2: '专为青少年健康数字阅读打造',
        text3: 'BUILT FOR TEEN E-HEALTH READING'
      }, {
        // index: 0,
        type: 1,
        url: require('../../assets/product_img2.png'),
        text1: 'BC520绘本通',
        text2: '专为儿童健康阅读打造的彩色电子纸终端',
        text3: 'A COLOR E-PAPER TERMINAL DESIGNED FOR CHILDRENS HEALITHY READING'
      }, {
        // index: 1,
        type: 2,
        url: require('../../assets/product_img1.png'),
        text1: 'BC60A启明星',
        text2: '专为青少年健康数字阅读打造',
        text3: 'BUILT FOR TEEN E-HEALTH READING'
      }];
    });
    var banner_active = ref(0);

    var changeItem = function changeItem(e) {
      banner_active.value = e;
    };

    return {
      bannerList: bannerList,
      bannerList2: bannerList2,
      bannerIndex: bannerIndex,
      changeBanner: changeBanner,
      handleHuiben: handleHuiben,
      handleQimingxing: handleQimingxing,
      topbanner: topbanner,
      next: next,
      pre: pre,
      handlebackTop: handlebackTop,
      banner_active: banner_active,
      changeItem: changeItem
    };
  }
});